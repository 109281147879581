import React from 'react';
import './../../styles/dashboard_main/TopNavigationBar.css';
import { FaBell, FaCog, FaUserCircle } from 'react-icons/fa';

const TopNavigationBar = () => {
  return (
    <div className="top-nav">
      {/* Left Section: Logo and Website Name */}
      <div className="logo-section">
        <img src="https://via.placeholder.com/40" alt="Logo" className="logo" />
        <h1 className="website-name">Loan Management System</h1>
      </div>

      {/* Right Section: Notifications, Settings, and Profile */}
      <div className="user-controls">
        <FaBell className="icon" title="Notifications" />
        <FaCog className="icon" title="Settings" />
        <FaUserCircle className="icon profile-icon" title="Profile" />
      </div>
    </div>
  );
};

export default TopNavigationBar;
