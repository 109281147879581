import React from 'react';
import RegisterMandate from './../../components/registration/RegisterMandate';

function RegisterMandateView() {
  return (
    <div className="Registration">
      <RegisterMandate />
    </div>
  );
}

export default RegisterMandateView;
