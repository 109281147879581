import React from 'react';
import DashboardView from './../../components/dashboard/DashboardView';

function Dashboard() {
  return (
    <div className="Dashboard">
      <DashboardView />
    </div>
  );
}

export default Dashboard;
