import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages
import LandingPage from "./containers/landing";
import Login from "./containers/login";
import Dashboard_Main from "./containers/dashboard_main";
import NotfoundPage from "./containers/login/notfound";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/dashboard" component={Dashboard_Main} />
          <Route path="*" component={NotfoundPage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;