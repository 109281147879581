import React, { useState } from 'react';
import './../../styles/collection/CollectionView.css';
import { FaSearch, FaEye, FaTimes } from 'react-icons/fa'; // Add react-icons for icons
import CollectionData from './CollectionData';
import LoanDetails from './LoanDetails';
import './../../styles/collection/CollectionView.css';

const collectionsData = [
  { customerId: "123456", loanId: "L-001", debitSuccess: 80 },
  { customerId: "234567", loanId: "L-002", debitSuccess: 90 },
  { customerId: "345678", loanId: "L-003", debitSuccess: 70 },
  { customerId: "456789", loanId: "L-004", debitSuccess: 60 },
  { customerId: "567890", loanId: "L-005", debitSuccess: 50 },
];

const loanDetails = {
  loanId: "123456",
  amount: "₹10,000",
  disbursalDate: "Jun 20, 2023",
  dueDate: "Jul 20, 2023",
  status: "Not Repaid",
  transactions: [
    { type: "EDI", status: "Success", amount: "₹1,000", paymentId: "123456", debitUtr: "BANK123", timestamp: "Jun 20, 2023" },
    { type: "EDI", status: "Failure", amount: "₹1,000", paymentId: "123457", debitUtr: "BANK124", timestamp: "Jun 21, 2023" },
    { type: "Bounce", status: "Failure", amount: "₹1,000", paymentId: "123458", debitUtr: "BANK125", timestamp: "Jun 22, 2023" },
  ],
};

const CollectionView = () => {
  const [selectedLoan, setSelectedLoan] = useState(null);

  const handleViewLoan = (loanId) => {
    setSelectedLoan(loanDetails);
  };

  return (
    <div className="collections-container">
      <div className="search-bars">
        <div className="search-input-container">
          <FaSearch className="search-icon" />
          <input type="text" className="search-input" placeholder="Search by Customer ID" />
        </div>
        <div className="search-input-container">
          <FaSearch className="search-icon" />
          <input type="text" className="search-input" placeholder="Search by Loan ID" />
        </div>
      </div>
      {!selectedLoan ? (
        <CollectionData collections={collectionsData} onViewLoan={handleViewLoan} />
      ) : (
        <div className="loan-details-container">
          <button className="close-button" onClick={() => setSelectedLoan(null)}>✖</button>          
          <h2>View Loan Details</h2>
          <div className="loan-details">
            <div className="loan-info">
              <p><strong>Loan ID:</strong> {selectedLoan.loanId}</p>
              <p><strong>Loan Amount:</strong> {selectedLoan.amount}</p>
              <p><strong>Loan Disbursal Date:</strong> {selectedLoan.disbursalDate}</p>
              <p><strong>Loan Due Date:</strong> {selectedLoan.dueDate}</p>
              <p><strong>Loan Repayment Status:</strong> {selectedLoan.status}</p>
            </div>

            <h3>Repayment Transactions</h3>
            <table className="repayment-table">
              <thead>
                <tr>
                  <th>Type Of Debit</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Payment Transaction ID</th>
                  <th>Debit UTR</th>
                  <th>Status Update Time Stamp</th>
                </tr>
              </thead>
              <tbody>
                {selectedLoan.transactions.map((txn, index) => (
                  <tr key={index}>
                    <td>{txn.type}</td>
                    <td>
                      <span className={`status-badge ${txn.status.toLowerCase()}`}>{txn.status}</span>
                    </td>
                    <td>{txn.amount}</td>
                    <td>{txn.paymentId}</td>
                    <td>{txn.debitUtr}</td>
                    <td>{txn.timestamp}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectionView;
