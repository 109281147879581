import React, { useState } from 'react';
import './../../styles/registration/RegisterMandate.css';
import { Link } from 'react-router-dom'; // Import Link

const RegisterMandate = () => {
  // Form Data State
  const [formData, setFormData] = useState({
    customer_identifier: '',
    customer_name: '',
    first_collection_date: '',
    final_collection_date: '',
    maximum_amount: '',
    corporate_config_id: '',
    expire_in_days: 30, // Fixed value
    frequency: 'ADHO', // Non-editable
    customer_ref_number: '',
    scheme_ref_number: '',
    account_validation: true, // Non-editable
    customer_account_number: '',
    loan_id: '',
  });

  const [templates, setTemplates] = useState([]);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateName, setTemplateName] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic...
    const today = new Date();
    const firstCollectionDate = new Date(formData.first_collection_date);
    const finalCollectionDate = new Date(formData.final_collection_date);

    if (firstCollectionDate < today) {
      alert('First collection date cannot be in the past.');
      return;
    }

    const maxFinalCollectionDate = new Date(firstCollectionDate);
    maxFinalCollectionDate.setFullYear(maxFinalCollectionDate.getFullYear() + 40);

    if (finalCollectionDate > maxFinalCollectionDate) {
      alert('Final collection date cannot be more than 40 years from the first collection date.');
      return;
    }

    // Logic for mandate registration submission
    console.log(formData);
  };

  // Open the export template modal
  const openExportTemplateModal = () => {
    setShowTemplateModal(true);
  };

  // Handle exporting template
  const handleExportTemplate = () => {
    const newTemplate = { name: templateName, data: formData };
    setTemplates([...templates, newTemplate]);
    setShowTemplateModal(false);
    setTemplateName(''); // Reset template name
  };

  // Handle clicking on a template from the list
  const loadTemplate = (templateData) => {
    setFormData(templateData);
  };

  return (
    <div className="main-content">
      <div className="register-mandate-container">
        {/* Register Mandate Form */}
        <div className="register-mandate">
          <h2>Register New Mandate</h2>

          <form onSubmit={handleSubmit} className="mandate-form">
            {/* Form fields for registration */}
            <div className="form-group">
              <label>Customer Name</label>
              <input
                type="text"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Customer ID</label>
              <input
                type="text"
                name="customer_identifier"
                value={formData.customer_identifier}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Loan ID</label>
              <input
                type="text"
                name="loan_id"
                value={formData.loan_id}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>First Collection Date</label>
              <input
                type="date"
                name="first_collection_date"
                value={formData.first_collection_date}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Final Collection Date</label>
              <input
                type="date"
                name="final_collection_date"
                value={formData.final_collection_date}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Maximum Amount</label>
              <input
                type="number"
                name="maximum_amount"
                value={formData.maximum_amount}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Corporate Config ID</label>
              <select
                name="corporate_config_id"
                value={formData.corporate_config_id}
                onChange={handleChange}
                required
              >
                <option value="">Select Corporate Config ID</option>
                <option value="ABCD">ABCD</option>
                <option value="EFGH">EFGH</option>
                <option value="IJKL">IJKL</option>
              </select>
            </div>

            <div className="form-group">
              <label>Expire in Days</label>
              <input
                type="text"
                name="expire_in_days"
                value={formData.expire_in_days}
                readOnly
              />
            </div>

            <div className="form-group">
              <label>Frequency</label>
              <input type="text" name="frequency" value={formData.frequency} readOnly />
            </div>

            <div className="form-group">
              <label>Account Validation</label>
              <input type="checkbox" name="account_validation" checked={formData.account_validation} readOnly />
            </div>

            <div className="form-group">
              <label>Customer Account Number</label>
              <input
                type="text"
                name="customer_account_number"
                value={formData.customer_account_number}
                onChange={handleChange}
                required
              />
            </div>

            {/* Form Buttons including Export Template */}
            {/* Form Buttons including Export Template */}
            <div className="form-buttons">
              <button type="submit" className="button submit-button">Submit</button>
              <Link to="/registrations" className="button cancel-button">Cancel</Link>
              <button type="button" className="button export-template-button" onClick={openExportTemplateModal}>
                Export Template
              </button>
            </div>

          </form>
        </div>

        {/* Template List as Tiles on the Right */}
        <div className="template-list-container">
          <h3>Templates</h3>
          <div className="template-tiles">
            {templates.map((template, index) => (
              <div className="template-tile" key={index} onClick={() => loadTemplate(template.data)}>
                <span>{template.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Template Export Modal */}
      {showTemplateModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Export Template</h3>
            <label>Template Name</label>
            <input
              type="text"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              required
            />
            <div className="modal-buttons">
              <button onClick={handleExportTemplate}>Save</button>
              <button onClick={() => setShowTemplateModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterMandate;