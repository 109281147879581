// src/components/landing/LandingPage.js
import React from 'react';
import NavBar from './../../components/landing/NavBar';
import Header from './../../components/landing/Header';
import MainContent from './../../components/landing/MainContent';
import Footer from './../../components/landing/Footer';
import HeroSection from '../../components/landing/HeroSection';
import CollectionFeaturesSection from '../../components/landing/CollectionFeaturesSection';
import FeatureStepsSection from '../../components/landing/FeatureStepsSection';
import './../../styles/landing/Landing.css'; // Link the CSS file


const LandingPage = () => {
  return (
    <div className="landing-page">
      {/* < Header /> */}
      <NavBar/>
      <HeroSection/>
      <FeatureStepsSection/>
      {/* <CollectionFeaturesSection/> */}
      {/* <MainContent /> */}
      <Footer />
    </div>
  );
};

export default LandingPage;
