import React from 'react';
import CollectionView from './../../components/collection/CollectionView';

function Collection() {
  return (
    <div className="Collection">
      <CollectionView />
    </div>
  );
}

export default Collection;
