import React, { useState } from 'react';
import './../../styles/landing/PopupForm.css'; // Link the CSS file

const PopupForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    description: ''
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://127.0.0.1:8000/api/accounts/userInfosubmit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Form submitted successfully');
        
        // Store data in localStorage
        localStorage.setItem('userInfo', JSON.stringify(data));

        onClose();
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Failed to submit form');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="popup-form">
      <div className="popup-form-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <form onSubmit={handleSubmit}>
          <label>
            Name :
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Email :
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Number :
            <input type="tel" name="number" value={formData.number} onChange={handleChange} required />
          </label>
          <label>
            Description :
            <textarea name="description" value={formData.description} onChange={handleChange} required />
          </label>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;