// src/components/HeroSection.js
import React from 'react';
import './../../styles/landing/HeroSection.css'; // Link the CSS file
import PopupForm from './PopupForm';

const HeroSection = () => {

  const [isPopupVisible, setIsPopupVisible] = React.useState(false);

  const handleButtonClick =() => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {  
  setIsPopupVisible(false);
  };

  return (
    <section className="hero-section">
       <div className="hero-content">
        <h1 className="hero-title">One Stop Platform for</h1>
        <div className="animated-words">
          <span className="word">Seamless Collections</span>
          <span className="word">Smarter Insights</span>
          <span className="word">Zero Delays</span>
        </div>
        <div className="hero-buttons">
          <button className="hero-button primary-button" onClick = {handleButtonClick}> Request Meeting </button>
        </div>
      </div>
      {isPopupVisible && <PopupForm onClose={handleClosePopup} />}
    </section>
  );
};


export default HeroSection;
