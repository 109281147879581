import React, { useState } from 'react';
import './../../styles/dashboard_main/NavigationSidebar.css';
import { FaHome, FaListAlt, FaFileInvoiceDollar, FaChartBar } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const NavigationSidebar = () => {
  const [active, setActive] = useState('dashboard');

  const handleNavClick = (page) => {
    setActive(page);
  };

  return (
    <div className="sidebar">
      <div className="nav-links">
        <Link 
          to="/dashboard"
          className={`nav-link ${active === 'dashboard' ? 'active' : ''}`}
          onClick={() => handleNavClick('dashboard')}
        >
          <FaHome className="icon" /> Home
        </Link>
        <Link 
          to="/registrations"
          className={`nav-link ${active === 'registrations' ? 'active' : ''}`}
          onClick={() => handleNavClick('registrations')}
        >
          <FaListAlt className="icon" /> Registrations
        </Link>
        <Link 
          to="/collections"
          className={`nav-link ${active === 'collections' ? 'active' : ''}`}
          onClick={() => handleNavClick('collections')}
        >
          <FaFileInvoiceDollar className="icon" /> Collections
        </Link>
        <Link 
          to="/reports"
          className={`nav-link ${active === 'reports' ? 'active' : ''}`}
          onClick={() => handleNavClick('reports')}
        >
          <FaChartBar className="icon" /> Reports
        </Link>
      </div>
    </div>
  );
};

export default NavigationSidebar;
