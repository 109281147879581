import React, { useState } from 'react';
import './../../styles/registration/RegistrationView.css';
import { Link } from 'react-router-dom';

const RegistrationView = () => {
  const [active, setActive] = useState('registrations');
  const [registrations, setRegistrations] = useState([
    { customerId: '123456', loanId: 'L-001', status: 'Registered', umrn: 'UMRN123456' },
    { customerId: '234567', loanId: 'L-002', status: 'Pending', umrn: 'NA' },
    { customerId: '345678', loanId: 'L-003', status: 'Failed', umrn: 'NA' },
    { customerId: '456789', loanId: 'L-004', status: 'Registered', umrn: 'UMRN456789' },
    { customerId: '567890', loanId: 'L-005', status: 'Processing', umrn: 'NA' },
    { customerId: '678901', loanId: 'L-006', status: 'Failed', umrn: 'NA' },
    { customerId: '789012', loanId: 'L-007', status: 'Registered', umrn: 'UMRN789012' },
    { customerId: '890123', loanId: 'L-008', status: 'Pending', umrn: 'NA' },
    { customerId: '901234', loanId: 'L-009', status: 'Processing', umrn: 'NA' },
    { customerId: '012345', loanId: 'L-010', status: 'Registered', umrn: 'UMRN012345' }
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false); // Modal state
  const [selectedIndex, setSelectedIndex] = useState(null); // Store selected index for cancel
  const recordsPerPage = 2;

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRegistrations = registrations.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(registrations.length / recordsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCancel = () => {
    const updatedRegistrations = [...registrations];
    updatedRegistrations[selectedIndex].status = 'Cancelled';
    setRegistrations(updatedRegistrations);
    setShowModal(false); // Close modal after cancel
  };

  const openCancelModal = (index) => {
    setSelectedIndex(index);
    setShowModal(true); // Open modal
  };

  const handleResend = (index) => {
    console.log(`Resend initiated for Customer ID: ${registrations[index].customerId}`);
  };

  return (
    <div className="main-content">
      <div className="header">
        <h2>Registrations</h2>
        <Link
          to="/register-mandate"
          className={`register-button ${active === 'registrations' ? 'active' : ''}`}
          onClick={() => setActive('register-mandate')}
        >
          Register New Mandate
        </Link>
      </div>

      <table className="registration-table">
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Loan ID</th>
            <th>Mandate Registration Status</th>
            <th>UMRN</th>
            <th>View Details</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRegistrations.map((registration, index) => (
            <tr key={index}>
              <td>{registration.customerId}</td>
              <td>{registration.loanId}</td>
              <td>
                <span className={`status ${registration.status.toLowerCase()}`}>
                  {registration.status}
                </span>
              </td>
              <td>{registration.umrn}</td>
              <td>
                <button className="view-details-button">View</button>
              </td>
              <td>
                {registration.status === 'Processing' ? (
                  <button
                    className="resend-button"
                    onClick={() => handleResend(index)}
                  >
                    Resend
                  </button>
                ) : (
                  <button
                    className="cancel-button"
                    onClick={() => openCancelModal(index)}
                    disabled={registration.status === 'Cancelled' || registration.status === 'Failed'}
                  >
                    Cancel
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
        <span>Page <strong>{currentPage}</strong> of {totalPages}</span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
      </div>

      {/* Confirmation Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Cancellation</h3>
            <p>Are you sure you want to cancel the registration?</p>
            <div className="modal-buttons">
              <button className="confirm-button" onClick={handleCancel}>Yes, Cancel</button>
              <button className="cancel-modal-button" onClick={() => setShowModal(false)}>No, Go Back</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrationView;
