import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DashboardLayout from './../../components/dashboard_main/DashboardLayout';
import Registration from './../registration';
import Collection from './../collection';
import Dashboard from './../dashboard';
import RegisterMandateView from './../register_mandate_view';

function Dashboard_Main() {
  return (
    <Router>
      <DashboardLayout>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/registrations" component={Registration} />
          <Route path="/collections" component={Collection} />
          <Route path="/register-mandate" component={RegisterMandateView} />
          <Route path="/reports" component={() => <h2>Reports Page</h2>} />
          {/* Add additional routes as needed */}
        </Switch>
      </DashboardLayout>
    </Router>
  );
}

export default Dashboard_Main;
