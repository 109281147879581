// src/components/landing/Footer.js
import React from 'react';
// import './../../styles/landing/Landing.css';
import './../../styles/landing/Footer.css';


const Footer = () => {
  return (
    <footer className="landing-footer">
      <div className="footer-container">
        <p>&copy; 2024 PlatzO. All rights reserved.</p>
        <div className="footer-links">
          <a href="/terms" className="footer-link">Terms of Service</a>
          <span className="link-separator">|</span>
          <a href="/privacy" className="footer-link">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
};


export default Footer;
