import React from 'react';
import './../../styles/dashboard/DashboardView.css';
import OverviewCard from './OverviewCard';

const DashboardView = () => {
  return (
    <div className="dashboard">
      <div className="dash-header">
        <h1>Portfolio Overview</h1>
        <p>Here’s how your loans are performing</p>
      </div>
      <div className="overview">
        <OverviewCard
          title="Portfolio Performance"
          value="$1.2M"
          change="+5%"
          timeFrame="Last 30 days"
          chartData={[10, 20, 15, 25, 18, 22]}
        />
        <OverviewCard
          title="Last 7 days Collections"
          value="+5%"
          change="-2%"
          timeFrame="Last 7 days"
          chartData={[5, 10, 8, 12, 11]}
        />
        <OverviewCard
          title="Last 7 days total amount bounce"
          value="$300K"
          change="-3%"
          timeFrame="Last 7 days"
          chartData={[5, 7, 6, 8, 6]}
        />
      </div>
      <div className="total-collection">
        <p>Total collection pending as of date</p>
        <h2>$150K</h2>
        <p>Next Scheduled Debit Date: April 15, 2023</p>
        <p>Next Scheduled Debit Time: 10:00 AM</p>
      </div>
    </div>
  );
};

export default DashboardView;
